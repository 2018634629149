import React from 'react';
import { graphql } from 'gatsby';
import { Layout } from '../components/layout';
import { HeadMeta } from '../components/layout/meta';
import { getPublishedIssues } from '../model/issue';
import { GatsbyImage } from 'gatsby-plugin-image';
import classNames from 'classnames';

export default function AboutPage({
  data: {
    wpPage,
    wp: {
      siteOptionsMetaboxSettings: { optionsIssuesHomepage: issueIds },
    },
    allWpTghpTaxonomyIssue: { nodes: allIssues },
  },
}) {
  const {
    content,
    featuredImage,
    tghpwipAboutPageTitle: pageTitle,
    tghpwipAboutPageTeamMemberData: teamMembers,
    seo,
  } = wpPage;

  let image;
  let publishedIssues = getPublishedIssues(issueIds, allIssues);

  if (featuredImage) {
    image = featuredImage?.node;
  } else if (publishedIssues && publishedIssues[0]?.tghpwipIssuesTaxonomyFeaturedImage?.nodes[0]) {
    image = publishedIssues[0].tghpwipIssuesTaxonomyFeaturedImage.nodes[0];
  }

  return (
    <Layout location={'about'} seoData={seo} wpPage={wpPage}>
      <div className="about-page">
        <div className="about-page__grid">
          <div
            className={classNames([
              'about-page__image',
              ...(!!image
                ? [
                    !!image.gatsbyImage && 'about-page__image--from-issue',
                    !!image.publicUrl && 'about-page__image--from-page',
                  ]
                : []),
            ])}
          >
            {!!image && (
              <>
                {!!image.gatsbyImage && <GatsbyImage alt="" image={image.gatsbyImage} />}
                {!!image.publicUrl && (
                  <img src={image.publicUrl} srcSet={image.srcSet} alt={image.altText} />
                )}
              </>
            )}
          </div>
          {pageTitle && (
            <div className="about-page__title">
              <span>{pageTitle}</span>
            </div>
          )}
          <div className="about-page__content" dangerouslySetInnerHTML={{ __html: content }} />
          <div className="about-page__team">
            {teamMembers.map(({ teamMemberName, teamMemberBio }, idx) => (
              <div className="about-page__team-member" key={idx}>
                <div className="about-page__member-title">{teamMemberName}</div>
                <div
                  className="about-page__member-bio"
                  dangerouslySetInnerHTML={{ __html: teamMemberBio }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($slug: String!) {
    wpPage(slug: { eq: $slug }) {
      title
      slug
      content
      featuredImage {
        node {
          altText
          publicUrl
          srcSet
        }
      }
      tghpwipAboutPageTitle
      tghpwipAboutPageTeamMemberData {
        teamMemberBio
        teamMemberName
      }
      ...SeoData
    }

    wp {
      ...SiteOptions
    }

    allWpTghpTaxonomyIssue(filter: { tghpwipIssuesTaxonomyCategory: { ne: "film" } }) {
      nodes {
        ...AllIssues
      }
    }
  }
`;

export const Head = () => <HeadMeta />;
